import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { slydrLogoImage } from "./logo-image.module.styl"

export default function LogoImage() {
  const {
    file: { image: slydrLogo },
  } = useStaticQuery(
    graphql`
      {
        file(relativePath: { eq: "slydr/slydr_logo_full.png" }) {
          image: childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
      }
    `
  )

  return (
    <GatsbyImage
      image={slydrLogo.gatsbyImageData}
      className={slydrLogoImage}
      alt="Slydr logo"
    />
  )
}
