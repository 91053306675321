import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import A11yHint from "../a11y-hint"
import {
  downloadLinkWrapper,
  downloadLink,
  downloadLinkImage,
} from "./download-link.module.styl"

export default function DownloadLink({
  gameName,
  gameId,
  utmCampaign,
  campaignId,
}) {
  const {
    file: { image: playStoreBadge },
  } = useStaticQuery(
    graphql`
      {
        file(relativePath: { eq: "games/play_store_download_badge.png" }) {
          image: childImageSharp {
            gatsbyImageData(
              width: 646
              layout: CONSTRAINED
              placeholder: BLURRED
            )
          }
        }
      }
    `
  )

  return (
    <div className={downloadLinkWrapper}>
      <OutboundLink
        className={downloadLink}
        href={`https://play.google.com/store/apps/details?id=${gameId}&utm_source=sqog.games&utm_campaign=${utmCampaign}&pcampaignid=${campaignId}`}
        target="_self"
        rel="noopener"
      >
        <A11yHint>{`Download ${gameName}`}</A11yHint>
        <GatsbyImage
          image={playStoreBadge.gatsbyImageData}
          className={downloadLinkImage}
          width="280"
          height="108.36"
          alt={`Download ${gameName} on Google Play`}
        />
      </OutboundLink>
    </div>
  )
}
