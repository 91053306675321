import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import A11yHint from "../../a11y-hint"
import { titleImage } from "./title-image.module.styl"

export default function TitleImage() {
  const {
    file: { image: plingTitle },
  } = useStaticQuery(
    graphql`
      {
        file(relativePath: { eq: "pling/pling_logo.png" }) {
          image: childImageSharp {
            gatsbyImageData(
              width: 240
              layout: CONSTRAINED
              placeholder: BLURRED
            )
          }
        }
      }
    `
  )

  return (
    <>
      <A11yHint>pling</A11yHint>
      <GatsbyImage
        image={plingTitle.gatsbyImageData}
        className={titleImage}
        alt="pling"
      />
    </>
  )
}
