import * as React from "react"
import Description from "../description"
import DownloadLink from "../download-link"
import GameSection from "../game-section"
import LogoImage from "./logo-image"
import TitleImage from "./title-image"

export default function Slydr() {
  return (
    <GameSection id="slydr" heading={<TitleImage />}>
      <LogoImage />
      <Description>
        Slydr is a sliding puzzle game which mixes elements of a Rubik's Cube
        with a sliding-tile puzzle to create a new, fun puzzle which is simple
        to play but challenging to perfect.
      </Description>
      <DownloadLink
        gameName="Slydr"
        gameId="games.sqog.slydr"
        utmCampaign="homepages"
        campaignId="pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
      />
    </GameSection>
  )
}
