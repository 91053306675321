import * as React from "react"
import Page from "../components/page"
import Pling from "../components/games/pling"
import Slydr from "../components/games/slydr"

export default function OurGames() {
  return (
    <Page seoProps={{ title: "our games" }}>
      <h2>our games</h2>
      <Pling />
      <Slydr />
    </Page>
  )
}
