import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage, withArtDirection } from "gatsby-plugin-image"
import { plingImage as plingImageClass } from "./logo-image.module.styl"
import "./logo-image.styl"

export default function LogoImage() {
  const {
    mainImage: { image: plingImage },
    smallImage: { image: plingSmallImage },
  } = useStaticQuery(
    graphql`
      {
        mainImage: file(relativePath: { eq: "pling/pling_image.png" }) {
          image: childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        smallImage: file(relativePath: { eq: "pling/pling_image_small.png" }) {
          image: childImageSharp {
            gatsbyImageData(
              width: 420
              layout: CONSTRAINED
              placeholder: BLURRED
            )
          }
        }
      }
    `
  )

  return (
    <GatsbyImage
      image={withArtDirection(getImage(plingImage), [
        {
          image: getImage(plingSmallImage),
          media: "(max-width: 460px)",
        },
      ])}
      className={`${plingImageClass} plingImage`}
      loading="eager"
      alt="pling - get pucked up"
    />
  )
}
