import * as React from "react"
import { gameSection, gameHeading } from "./game-section.module.css"

export default function GameSection({ children, heading, ...props }) {
  return (
    <section className={gameSection} {...props}>
      <h3 className={gameHeading}>{heading}</h3>
      {children}
    </section>
  )
}
