import * as React from "react"
import Description from "../description"
import DownloadLink from "../download-link"
import GameSection from "../game-section"
import LogoImage from "./logo-image"
import TitleImage from "./title-image"

export default function Pling() {
  return (
    <GameSection id="pling" heading={<TitleImage />}>
      <LogoImage />
      <Description>
        Pop squidgers, blow stuff up, go bowling, and make huge goals.
      </Description>
      <Description>
        pling is a top-down puck golf game full of bouncy action, big jumps,
        tricky shots, and hidden shortcuts. Take aim and flick your puck through
        50+ levels which test your timing, accuracy, and problem solving skills.
      </Description>
      <Description>
        Navigate a variety of hazards, from lasers to explosive barrels, as well
        as huge jumps and exposed edges where you can fall to your death. You'll
        need a steady hand and precise aim to get your puck to the hole in one
        piece.
      </Description>
      <Description>
        Rack up points by popping squidgers, or scoring strikes on special
        bowling levels. Points give you puckbucks, which let you customise your
        puck and the game by purchasing from over 100 cosmetic items in the
        store. You can also buy powerups which allow you to solve the levels in
        new ways and get even more points in the process.
      </Description>
      <DownloadLink
        gameName="pling"
        gameId="games.sqog.pling"
        utmCampaign="homepages"
        campaignId="pling-may-22-1"
      />
    </GameSection>
  )
}
