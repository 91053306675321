import * as React from "react"
import getClass from "../utils/get-class"
import { a11yHintWrapper, a11yHint } from "./a11y-hint.module.styl"

export default function A11yHint(props) {
  return (
    <span className={a11yHintWrapper}>
      <span {...props} className={getClass(a11yHint, props.className)} />
    </span>
  )
}
